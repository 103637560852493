
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Toast başlık rengi */
#toast-container > .toast-success .toast-title {
  color: #4CAF50; /* Başarı mesajı için yeşil */
}

#toast-container > .toast-error .toast-title {
  color: #F44336; /* Hata mesajı için kırmızı */
}

/* Toast mesaj içeriği rengi */
#toast-container > .toast-success .toast-message {
  color: #4CAF50; /* Başarı mesajı için yeşil */
}

#toast-container > .toast-error .toast-message {
  color: #F44336; /* Hata mesajı için kırmızı */
}

/* Başarı bildirimleri için yeşil arka plan */
.toast-success {
  background-color: green !important;
  color: white !important; /* Yazı rengini beyaz yap */
}

/* Genel olarak diğer bildirime özel stiller eklemek için örnek */
.toast-error {
  background-color: red !important;
  color: white !important;
}

.toast-info {
  background-color: blue !important;
  color: white !important;
}

.toast-warning {
  background-color: orange !important;
  color: white !important;
}

